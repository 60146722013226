import React, {useState} from 'react'
import {FilePond} from "react-filepond";


import {API_HOST, getToken} from '../../config/config';
import 'filepond/dist/filepond.min.css'


export default function DragAndDrop(props) {
  const [files, setFiles] = useState([])
  const [pond, setPond] = useState(null)

  return (
    <FilePond
      ref={(ref) => (setPond(ref))}
      files={files}
      onupdatefiles={setFiles}
      allowMultiple={true}
      maxFiles={5}
      chunkUploads={true}
      chunkSize={5000000} // 5MB
      onaddfile={(error, file) => {
        if (!error) {
          file.setMetadata('filename', file.filename);
        } else {
          console.error('Error adding file:', error);
        }
      }}
      onaddfilestart={(file => {
        props.setFileIsUploading(true);
      })}
      onprocessfiles={()=>{
        props.setUploadFiles(pond.getFiles())
        props.setFileIsUploading(false);
      }}
      onremovefile={(error, file) => {
        props.setUploadFiles(pond.getFiles())
      }}
      server={{
        url:`${API_HOST}upload`,
        headers: {
          'Authorization': 'Bearer ' + getToken(),
        },
      }}
      name="file"
      labelIdle='Ziehen Sie Ihre Dateien hierher oder <span class="filepond--label-action">wählen Sie diese aus</span>'
      credits={false}
      labelFileProcessing="Dateien werden hochgeladen"
      labelFileProcessingComplete="Dateien hochgeladen"
      labelTapToUndo="rückgängig"
      labelTapToCancel="abbrechen"
    />
  )
}

